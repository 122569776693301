*{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "PT Sans", sans-serif;
}

.page-container {
  position: relative;
  min-height: 100vh;
}

.content-wrap {
  padding-bottom: 140px;
}

@media screen and (max-width: 960px) {
  .content-wrap {
    padding-bottom: 170px;
  }
}

